

































































































































import dayjs from 'dayjs'
import { Component, Vue } from 'vue-property-decorator'
import controller from '@/app/ui/controllers/BannerController'
import { EventBus, EventBusConstants, Utils } from '@/app/infrastructures/misc'
import Modal from '@/app/ui/components/Modal/index.vue'
import LoadingOverlay from '@/app/ui/components/LoadingOverlay/index.vue'
import Badge from '@/app/ui/components/Badge/index.vue'
import Button from '@/app/ui/components/Button/index.vue'
import EditIcon from '@/app/ui/assets/edit_icon.vue'

@Component({
  components: {
    Badge,
    Modal,
    Button,
    EditIcon,
    LoadingOverlay,
  },
})
export default class BannerDetail extends Vue {
  isGuest = Utils.isGuest()
  controller = controller
  confirmationModal = false
  successModal = false
  isVisible = false

  created() {
    controller.getBannerDetail(this.$route.params.id)
    controller.getOrderBanner()

    EventBus.$on(EventBusConstants.CANCEL_BANNER_SUCCESS, () => {
      this.successModal = true
    })
  }

  get statusBadgeType() {
    if (this.controller.bannerDetail?.status?.toLowerCase() === 'cancelled') {
      return 'error'
    } else if (
      this.controller.bannerDetail?.status?.toLowerCase() === 'expired'
    ) {
      return 'expire'
    } else if (
      this.controller.bannerDetail?.status?.toLowerCase() === 'active'
    ) {
      return 'success'
    } else {
      return 'warning'
    }
  }

  get isHideButton() {
    return controller.bannerDetail.status !== 'Expired' && controller.bannerDetail.status !== 'Cancelled'
  }

  private cancelBanner() {
    controller.cancelBanner(this.$route.params.id)
    this.confirmationModal = false
  }

  private onCloseSuccessModal() {
    controller.getBannerDetail(this.$route.params.id)
    this.successModal = false
  }

  private onEditBanner() {
    const orderBanner = this.controller.orderBanner.value
    const bannerId = this.controller.bannerDetail.id
    if (orderBanner === 0) {
      this.isVisible = true
    } else {
      this.$router.push(`/banner/banner-in-app/${bannerId}/edit`)
    }
  }

  private formatDate(date: string) {
    return dayjs(date).format('DD MMMM YYYY[\n]HH:mm [WIB]')
  }
}
